"use client"

import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

export default function NavbarLinkWithDrawer ({linkData}) {
  const router  = useRouter();
  const [currentUrl, setCurrentUrl] = useState("");

  function downloadICARegister () {
    const fileName = "Registro-ICA.pdf"
    const urlOrigin = window.location.origin

    const aTag = document.createElement("a")
    aTag.href = `${urlOrigin}/${fileName}`
    aTag.setAttribute("download", fileName)
    document.body.appendChild(aTag)

    aTag.click()
    aTag.remove()
  }

  useEffect(() => {
    setCurrentUrl(window.location.origin)
  }, [])

  return (
    <div
      className="h-full lg:px-[15px] xl:px-[20px] 2xl:px-[35px] nav-link__cont"
    >
      <div className='nav-link relative bg-black z-20 h-full'>
        <div className="nav-link-inner-cont w-full h-full flex items-center ">
          <p>{linkData.label}</p>
        </div>
      </div>
      <div className='drawer w-screen opacity-0 h-[0px] z-10 absolute -bottom-[1px] left-0 rounded-b-sm'>
        <ul className='drawer-list hidden w-full lg:px-16 xl:px-24 2xl:w-[90%] 2xl:px-0'>
          {linkData.services.map((service, index) => {
            var linksTextSizeStyles = "font-bold text-[20px]"
            if (index > 0) linksTextSizeStyles = "font-medium text-[15px]"
            
            return (
              <div
                className=' w-[450px] pt-6 flex justify-start'
                key={index}
              >
                <ul
                  className='w-full p-4 text-start'
                >
                  <h3 className='text-[25px] font-bold text-white mb-4'>{service.label}</h3>
                  {service.dataGroup.map((data, index) => {
                    return (
                      <li
                        key={index}
                        className={`transition hover:text-white my-1 hover:cursor-pointer ${linksTextSizeStyles}`}
                        onClick={() => {
                          console.log({currentUrl, label: data.label})
                          if(data.label === "Registro ICA") {
                            router.push(`${currentUrl}/Registro-ICA.pdf`)
                          } else if (data.label === "Registro ONAC") {
                            router.push(`${currentUrl}/Registro-ONAC.pdf`)
                          } else {
                            router.push(data.link)
                          }
                        }}
                      >
                        <p >{data.label}</p>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </ul>
      </div>
    </div>
  )
}